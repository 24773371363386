$color_1: #666;
$color_2: #333;
$color_3: #7f7f7f;
$color_4: black;
$color_5: #ce2a36;
$color_6: #999;
$color_7: #fff;
$color_8: rgba(255, 255, 255, .7);
$color_9: #e6e6e6;
$color_10: rgba(255, 255, 255, .65);
$color_11: #cc0033;
$color_12: #444;
$color_13: #777;
$color_14: rgba(255, 255, 255, 1);
$color_15: rgba(255, 255, 255, 0.6);
$color_16: #888;
$font_family_1: "Open Sans",
sans-serif;
$font_family_2: "Montserrat",
sans-serif;
$font_family_3: "FontAwesome";
$background_color_1: #fff;
$background_color_2: transparent;
$background_color_4: #444;
$background_color_6: #e5e5e5;
$border_color_1: rgba(231, 231, 231, 0);
$border_color_2: #fff;
$border_color_3: #999;

body,
html {
    font-family: $font_family_1;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    color: $color_1;
    font-weight: 400;
    width: 100% !important;
    height: 100% !important;
    scroll-behavior: smooth;
}

#root {
    display: inline-flexbox;
}

h2 {
    font-family: $font_family_2;
    margin: 0 0 20px 0;
    font-weight: 700;
    font-size: 32px;
    color: $color_2;
}

h3 {
    font-family: $font_family_2;
    font-size: 22px;
    font-weight: 700;
    color: $color_2;
}

h4 {
    font-size: 18px;
    color: $color_2;
}

p {
    font-size: 15px;
}

p.intro {
    margin: 12px 0 0;
    line-height: 24px;
}

a {
    color: #0079ff;
    font-weight: 600;

    &:hover {
        text-decoration: none;
        color: #0099ff;
    }

    &:focus {
        text-decoration: none;
        color: $color_4;
        outline: none;

    }
}

ul {
    list-style: none;
    padding: 0;
}

.section-title {
    margin-bottom: 70px;

    h2 {
        position: relative;
        margin-top: 10px;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.09);

        &::after {
            position: absolute;
            content: "";
            background-color: $background_color_4;
            height: 3px;
            width: 50px;
            bottom: -1px;
            left: 0;
        }
    }
}

.btn-custom {
    text-transform: uppercase;
    color: $color_7;
    background-color: $background_color_4;
    padding: 14px 20px;
    letter-spacing: 1px;
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    transition: all 0.3s;

    &:hover,
    &.hover {
        color: $color_7;
        background-color: $color_3;
    }

    &:focus,
    &.focus {
        color: $color_7;
        background-color: $color_3;
    }

    &:active,
    &.active {
        color: $color_7;
        background-color: $color_3;
    }
}

.btn {
    &:active {
        background-image: none;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;

        &:focus {
            outline: none;
        }
    }

    &:disabled,
    &.disabled {
        cursor: not-allowed;
    }

    &:focus {
        outline: none;
    }

    &:active.focus {
        outline: none;
    }
}

.btn.active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;

    &:focus {
        outline: none;
    }
}

.btn.focus {
    outline: none;
}

.btn.active.focus {
    outline: none;
}

.intro {
    display: inline-flexbox;
    width: 100%;
    padding: 0;
    background: url(../images/bg.jpg) center center no-repeat;
    background-color: $background_color_6;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;

    .overlay {
        background: rgba(0, 0, 0, 0.3);
    }

    h1 {
        font-family: $font_family_2;
        color: $color_7;
        font-size: 40px;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 10px;
        text-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    }

    p {
        color: $color_7;
        font-size: 17px;
        line-height: 28px;
        padding: 15px;
        max-width: 600px;
        margin: 0 auto;
        margin-bottom: 40px;
        background: rgba(0, 0, 0, .45);
    }
}

#get-touch {
    padding: 40px 0 30px;
    color: $color_8;
    background: $color_3;

    h3 {
        font-size: 24px;
        font-weight: 400;
        color: $color_7;
        margin: 0 0 10px 0;
    }

    .btn-custom {
        margin: 0;
        background: transparent;
        border: 2px solid #fff;

        &:hover,
        &:active {
            outline: none;
            color: $color_3;
            background: #fff;
            border: 2px solid #fff;
        }
    }
}

#about {
    padding: 80px 0;
    background: #f6f6f6;

    h3 {
        font-size: 22px;
        margin: 0 0 20px 0;
    }

    h2 {
        position: relative;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.09);

        &::after {
            position: absolute;
            content: "";
            background-color: $background_color_4;
            height: 3px;
            width: 50px;
            bottom: -1px;
            left: 0;
        }
    }

    .about-text {
        li {
            margin-bottom: 6px;
            margin-left: 6px;
            list-style: none;
            padding: 0;

            &:before {
                content: "\f00c";
                font-family: $font_family_3;
                color: $color_3;
                font-size: 11px;
                font-weight: 300;
                padding-right: 8px;
            }
        }
    }

    img {
        padding: 20px;
        width: 500px;
        margin-top: 10px;
        background: #fff;
        border-right: 0;
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
    }

    p {
        line-height: 24px;
        margin: 30px 0;
    }
}

#portfolio {
    padding: 80px 0;
    background: #f6f6f6;

    .section-title {
        margin-bottom: 0;
    }
}

#testimonials {
    padding: 70px 0;

    i {
        color: $color_9;
        font-size: 32px;
        margin-bottom: 20px;
    }
}

.testimonial {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 20px;

    img {
        width: 85%;
    }
}

#contact {
    padding: 60px 0 0 0;
    background: $color_3;
    color: $color_10;

    .section-title {
        margin-bottom: 40px;
    }

    h2 {
        color: $color_7;
        margin-top: 10px;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.09);
    }

    h4 {
        color: $color_7;
        margin-top: 22px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.09);
    }

    form {
        padding-top: 20px;
    }

    h3 {
        font-size: 17px;
        font-weight: 600;
    }

    .text-danger {
        color: $color_11;
        text-align: left;
    }

    .btn-custom {
        margin: 30px 0;
        background: transparent;
        border: 2px solid #fff;

        &:hover {
            color: $color_3;
            background: #fff;
        }
    }

    .form-control {
        display: block;
        width: 100%;
        padding: 6px 12px;
        font-size: 16px;
        line-height: 1.42857143;
        color: $color_12;
        background-color: $background_color_1;
        background-image: none;
        border: 1px solid #ddd;
        border-radius: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-transition: none;
        -o-transition: none;
        transition: none;

        &:focus {
            border-color: $border_color_3;
            outline: 0;
            -webkit-box-shadow: transparent;
            box-shadow: transparent;
        }
    }

    .contact-item {
        margin: 40px 0 0 0;

        span {
            color: $color_14;
            display: block;
        }
    }

    .social {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        padding: 20px 0;
        text-align: center;

        ul {
            li {
                display: inline-block;
                margin: 0 10px;
            }
        }

        i.fa {
            font-size: 24px;
            padding: 10px;
            color: $color_15;
            transition: all 0.3s;

            &:hover {
                color: $color_7;
            }
        }
    }
}

label {
    font-size: 12px;
    font-weight: 400;
    font-family: $font_family_1;
    float: left;
}

.form-control {
    &::-webkit-input-placeholder {
        color: $color_13;
    }

    &:-moz-placeholder {
        color: $color_13;
    }

    &::-moz-placeholder {
        color: $color_13;
    }

    &:-ms-input-placeholder {
        color: $color_13;
    }
}

#footer {
    background-color: $color_14;
    padding: 15px 0;

    p {
        color: $color_16;
        font-size: 16px;
        margin: 0;
    }

    a {
        color: $color_3;

        &:hover {
            color: $color_4;
        }
    }
}

@media (max-width: 768px) {
    #about {
        img {
            margin: 50px 0;
        }
    }
}

.grecaptcha-badge {
    bottom: 4rem !important;
}